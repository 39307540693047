/**
 * @generated SignedSource<<c7e4eec7c1d02b57811312d291210fba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type ProgramType = "A_A_3_D_ACCREDITATION" | "NONE" | "_3_D_STYLING" | "%future added value";
export type SoftwareType = "BROWZWEAR" | "CLO" | "NONE" | "OPTITEX" | "%future added value";
export type WorkflowType = "ACCREDITATION" | "%future added value";
export type TemplateDuplicatePageQuery$variables = {
  currentTemplateRowId: UUID;
};
export type TemplateDuplicatePageQuery$data = {
  readonly module: {
    readonly attempts: number;
    readonly flow: {
      readonly assignmentStatus: AssignmentStatus;
      readonly createdAt: Date | null;
      readonly description: string;
      readonly dueAt: Date | null;
      readonly durationSeconds: number;
      readonly flowReviewers: {
        readonly nodes: ReadonlyArray<{
          readonly reviewer: {
            readonly company: {
              readonly name: string;
            } | null;
            readonly firstName: string;
            readonly lastName: string;
            readonly rowId: UUID;
          } | null;
        }>;
      };
      readonly name: string;
      readonly rowId: UUID;
      readonly workflowType: WorkflowType;
    } | null;
    readonly galleryAssetComponentsId: UUID | null;
    readonly programType: ProgramType | null;
    readonly softwareType: SoftwareType | null;
    readonly tasksByModuleFlowId: {
      readonly nodes: ReadonlyArray<{
        readonly flow: {
          readonly id: string;
          readonly name: string;
          readonly rowId: UUID;
        } | null;
      }>;
    };
    readonly versionNumber: BigFloat;
  } | null;
};
export type TemplateDuplicatePageQuery = {
  response: TemplateDuplicatePageQuery$data;
  variables: TemplateDuplicatePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentTemplateRowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "flowId",
    "variableName": "currentTemplateRowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignmentStatus",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workflowType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationSeconds",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "PRIORITY_ASC"
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Flow",
  "kind": "LinkedField",
  "name": "flow",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v17/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "galleryAssetComponentsId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attempts",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplateDuplicatePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "module",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "FlowReviewersConnection",
                "kind": "LinkedField",
                "name": "flowReviewers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlowReviewer",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GwtUser",
                        "kind": "LinkedField",
                        "name": "reviewer",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "flowReviewers(first:1)"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v16/*: any*/),
            "concreteType": "TasksConnection",
            "kind": "LinkedField",
            "name": "tasksByModuleFlowId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "tasksByModuleFlowId(orderBy:\"PRIORITY_ASC\")"
          },
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TemplateDuplicatePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "module",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "FlowReviewersConnection",
                "kind": "LinkedField",
                "name": "flowReviewers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlowReviewer",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GwtUser",
                        "kind": "LinkedField",
                        "name": "reviewer",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "flowReviewers(first:1)"
              },
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v16/*: any*/),
            "concreteType": "TasksConnection",
            "kind": "LinkedField",
            "name": "tasksByModuleFlowId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v18/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "tasksByModuleFlowId(orderBy:\"PRIORITY_ASC\")"
          },
          (v19/*: any*/),
          (v20/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec2030d462134b412bab35cfa8c15a6f",
    "id": null,
    "metadata": {},
    "name": "TemplateDuplicatePageQuery",
    "operationKind": "query",
    "text": "query TemplateDuplicatePageQuery(\n  $currentTemplateRowId: UUID!\n) {\n  module(flowId: $currentTemplateRowId) {\n    programType\n    softwareType\n    versionNumber\n    flow {\n      name\n      rowId\n      assignmentStatus\n      workflowType\n      description\n      dueAt\n      createdAt\n      durationSeconds\n      flowReviewers(first: 1) {\n        nodes {\n          reviewer {\n            rowId\n            firstName\n            lastName\n            company {\n              name\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n      id\n    }\n    tasksByModuleFlowId(orderBy: PRIORITY_ASC) {\n      nodes {\n        flow {\n          name\n          id\n          rowId\n        }\n        id\n      }\n    }\n    galleryAssetComponentsId\n    attempts\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d294681471afd1102ad5281749b7a6c9";

export default node;
