/**
 * @generated SignedSource<<8ff409774447e50ced40051232beef3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type LinkedType = "MODULE" | "MODULE_GROUP" | "TASK" | "TEMPLATE" | "UNKNOWN" | "%future added value";
export type ToDoAndUpcomingContainerQuery$variables = {
  assignedToId: UUID;
};
export type ToDoAndUpcomingContainerQuery$data = {
  readonly flows: {
    readonly nodes: ReadonlyArray<{
      readonly assignedAt: Date | null;
      readonly assignedTo: {
        readonly id: string;
        readonly rowId: UUID;
      } | null;
      readonly assignmentStatus: AssignmentStatus;
      readonly dueAt: Date | null;
      readonly linkedType: LinkedType;
      readonly name: string;
      readonly rowId: UUID;
      readonly " $fragmentSpreads": FragmentRefs<"FlowLink_flow" | "StatusChip_flow">;
    }>;
  } | null;
};
export type ToDoAndUpcomingContainerQuery = {
  response: ToDoAndUpcomingContainerQuery$data;
  variables: ToDoAndUpcomingContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assignedToId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "assignedToId",
        "variableName": "assignedToId"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  },
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "assignmentStatus": {
        "notIn": [
          "ACCEPTED"
        ]
      }
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "GwtUser",
  "kind": "LinkedField",
  "name": "assignedTo",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkedType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignmentStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToDoAndUpcomingContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FlowsConnection",
        "kind": "LinkedField",
        "name": "flows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FlowLink_flow"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StatusChip_flow"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToDoAndUpcomingContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FlowsConnection",
        "kind": "LinkedField",
        "name": "flows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assignmentDueAtStatus",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eaa898c5cee16e08b60380f95d899301",
    "id": null,
    "metadata": {},
    "name": "ToDoAndUpcomingContainerQuery",
    "operationKind": "query",
    "text": "query ToDoAndUpcomingContainerQuery(\n  $assignedToId: UUID!\n) {\n  flows(condition: {assignedToId: $assignedToId}, filter: {assignmentStatus: {notIn: [ACCEPTED]}}) {\n    nodes {\n      rowId\n      name\n      assignedTo {\n        id\n        rowId\n      }\n      assignedAt\n      dueAt\n      linkedType\n      assignmentStatus\n      ...FlowLink_flow\n      ...StatusChip_flow\n      id\n    }\n  }\n}\n\nfragment FlowLink_flow on Flow {\n  rowId\n  linkedType\n}\n\nfragment StatusChip_flow on Flow {\n  dueAt\n  assignmentStatus\n  assignmentDueAtStatus\n}\n"
  }
};
})();

(node as any).hash = "6b90b965c5641eef0af1758a150ef14e";

export default node;
