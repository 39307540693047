/**
 * @generated SignedSource<<fb5c04812c72bd89c69f09acdef7b0cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ToDoCardQuery$variables = {
  groupModuleIds?: ReadonlyArray<UUID> | null;
};
export type ToDoCardQuery$data = {
  readonly moduleGroupLinks: {
    readonly nodes: ReadonlyArray<{
      readonly groupIndex: number;
      readonly moduleFlowId: UUID;
      readonly moduleGroupFlowId: UUID;
    }>;
  } | null;
};
export type ToDoCardQuery = {
  response: ToDoCardQuery$data;
  variables: ToDoCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupModuleIds"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "in",
            "variableName": "groupModuleIds"
          }
        ],
        "kind": "ObjectValue",
        "name": "moduleGroupFlowId"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "moduleGroupFlowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "moduleFlowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupIndex",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToDoCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModuleGroupLinksConnection",
        "kind": "LinkedField",
        "name": "moduleGroupLinks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleGroupLink",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToDoCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModuleGroupLinksConnection",
        "kind": "LinkedField",
        "name": "moduleGroupLinks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleGroupLink",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a65688c12aef01635ed83def18401a10",
    "id": null,
    "metadata": {},
    "name": "ToDoCardQuery",
    "operationKind": "query",
    "text": "query ToDoCardQuery(\n  $groupModuleIds: [UUID!]\n) {\n  moduleGroupLinks(filter: {moduleGroupFlowId: {in: $groupModuleIds}}) {\n    nodes {\n      moduleGroupFlowId\n      moduleFlowId\n      groupIndex\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d817a00894e60a25da35b38de749dd2a";

export default node;
