/**
 * @generated SignedSource<<c4180d053d80973d292315e2e9b9bc3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type ProgramType = "A_A_3_D_ACCREDITATION" | "NONE" | "_3_D_STYLING" | "%future added value";
export type SoftwareType = "BROWZWEAR" | "CLO" | "NONE" | "OPTITEX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AssignmentsTablePaginationFragment_modules$data = {
  readonly modules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly flow: {
          readonly assignedTo: {
            readonly company: {
              readonly name: string;
              readonly rowId: UUID;
            } | null;
            readonly fullName: string;
            readonly userPriority: number;
            readonly " $fragmentSpreads": FragmentRefs<"UserLink_user" | "UserModal_user" | "UserPriority_gwtUser">;
          } | null;
          readonly assignmentStatus: AssignmentStatus;
          readonly completedAt: Date | null;
          readonly dueAt: Date | null;
          readonly flowReviewers: {
            readonly nodes: ReadonlyArray<{
              readonly reviewer: {
                readonly fullName: string;
                readonly " $fragmentSpreads": FragmentRefs<"UserLink_user" | "UserModal_user">;
              } | null;
            }>;
          };
          readonly name: string;
          readonly rowId: UUID;
          readonly statusChangedAt: Date | null;
          readonly updatedAt: Date | null;
          readonly " $fragmentSpreads": FragmentRefs<"FlowLink_flow" | "StatusChip_flow">;
        } | null;
        readonly programType: ProgramType | null;
        readonly softwareType: SoftwareType | null;
        readonly " $fragmentSpreads": FragmentRefs<"AssignmentOptions_module">;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "AssignmentsTablePaginationFragment_modules";
};
export type AssignmentsTablePaginationFragment_modules$key = {
  readonly " $data"?: AssignmentsTablePaginationFragment_modules$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentsTablePaginationFragment_modules">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "modules"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UserModal_user"
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UserLink_user"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeArchived"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "moduleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "modulesOrderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./AssignmentsTablePaginationQuery_Refetchable.graphql')
    }
  },
  "name": "AssignmentsTablePaginationFragment_modules",
  "selections": [
    {
      "alias": "modules",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "moduleFilter"
        },
        {
          "kind": "Variable",
          "name": "includeArchived",
          "variableName": "includeArchived"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "modulesOrderBy"
        }
      ],
      "concreteType": "ModulesConnection",
      "kind": "LinkedField",
      "name": "__AssignmentsTable_modules_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModulesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AssignmentOptions_module"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "programType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "softwareType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Flow",
                  "kind": "LinkedField",
                  "name": "flow",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "FlowLink_flow"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "updatedAt",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "completedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dueAt",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "assignmentStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "statusChangedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FlowReviewersConnection",
                      "kind": "LinkedField",
                      "name": "flowReviewers",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FlowReviewer",
                          "kind": "LinkedField",
                          "name": "nodes",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "GwtUser",
                              "kind": "LinkedField",
                              "name": "reviewer",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/),
                                (v4/*: any*/),
                                (v5/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GwtUser",
                      "kind": "LinkedField",
                      "name": "assignedTo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "userPriority",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "UserPriority_gwtUser"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Company",
                          "kind": "LinkedField",
                          "name": "company",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StatusChip_flow"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "3fa46141501a571455d580876eb47ca1";

export default node;
