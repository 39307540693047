import {
  Button,
  Form,
  Grid,
  Layout,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { FormProvider, useForm } from 'react-hook-form'
import { ControlledFormField } from '../../../controlledComponents/ControlledFormField'
import { ProgramType } from '../../Comments/__generated__/CommentAddMutation.graphql'
import {
  CreateProfilePageNewProfileMutation,
  UserType,
} from './__generated__/CreateProfilePageNewProfileMutation.graphql'
import { CreateProfilePageUpdateProfileMutation } from './__generated__/CreateProfilePageUpdateProfileMutation.graphql'
import { useGwtMutation } from '../../../hooks'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CreateProfilePageQuery,
  UserStatus,
} from './__generated__/CreateProfilePageQuery.graphql'
import { getModuleProgramTypeText } from '../../../util/moduleUtils'
import './CreateProfilePage.scss'
import { PROGRAM_A_A_3D_ACCREDITATION } from '../../../constants/userConstants'
import { usePublishGlobalNotification } from '../../../hooks/useNotifications'

type CreateProfilePageFormType = {
  firstName: string
  lastName: string
  jobTitle: string
  companyId: string
  pronouns: string
  email: string
  programs: Array<ProgramType>
  userType: UserType
  status: UserStatus
  userPriority: string
}

// The reviewer dashboard uses some of the components from the admin dashboard
export const CreateProfilePage = () => {
  const navigate = useNavigate()
  const { publishToLocal } = usePublishGlobalNotification()
  const { userId } = useParams()

  const { gwtUser, companies } = useLazyLoadQuery<CreateProfilePageQuery>(
    graphql`
      query CreateProfilePageQuery($rowId: UUID!, $skip: Boolean!) {
        gwtUser(rowId: $rowId) @skip(if: $skip) {
          firstName
          lastName
          userType
          jobTitle
          userPriority
          company {
            rowId
            name
          }
          pronouns
          email
          programs
          status
        }
        companies {
          nodes {
            rowId
            name
          }
        }
      }
    `,
    { rowId: userId, skip: !userId },
  )

  const formMethods = useForm({
    defaultValues: {
      firstName: gwtUser?.firstName || '',
      lastName: gwtUser?.lastName || '',
      jobTitle: gwtUser?.jobTitle || '',
      companyId: gwtUser?.company?.rowId || undefined,
      pronouns: gwtUser?.pronouns || '',
      email: gwtUser?.email || '',
      programs: gwtUser?.programs || [],
      userType: gwtUser?.userType || 'EXTERNAL_USER',
      status: gwtUser?.status || 'ACTIVE',
      userPriority: `${gwtUser?.userPriority || 0}`,
    },
  })

  const {
    handleSubmit,
    formState: { isDirty },
    watch,
  } = formMethods

  const userType = watch('userType')

  const makeToast = ToastProvider.useToaster()

  const createMutation = useGwtMutation<CreateProfilePageNewProfileMutation>(
    graphql`
      mutation CreateProfilePageNewProfileMutation(
        $input: CreateGwtUserInput!
      ) {
        createGwtUser(input: $input) {
          gwtUser {
            firstName
            lastName
            jobTitle
            userPriority
            company {
              rowId
              name
            }
            pronouns
            email
            programs
            status
            userType
          }
        }
      }
    `,
  )

  const updateMutation = useGwtMutation<CreateProfilePageUpdateProfileMutation>(
    graphql`
      mutation CreateProfilePageUpdateProfileMutation(
        $input: UpdateGwtUserInput!
      ) {
        updateGwtUser(input: $input) {
          gwtUser {
            firstName
            lastName
            jobTitle
            userPriority
            company {
              rowId
              name
            }
            pronouns
            email
            programs
            status
            userType
          }
        }
      }
    `,
  )

  const submitCreateProfile = (data: CreateProfilePageFormType) => {
    if (createMutation.isInFlight || !isDirty) return

    createMutation.commit(
      {
        input: {
          gwtUser: {
            ...data,
            fullName: `${data.firstName} ${data.lastName}`.trim(),
            userPriority: Number.parseInt(data.userPriority),
            rowId: uuidv4(),
            lanId: uuidv4(),
          },
        },
      },
      () => {
        makeToast({
          type: 'success',
          heading: 'Profile creation',
          message: 'Successfully created new user profile.',
        })
        publishToLocal('USER_PROFILE_CREATE_NOTIFICATION')
        navigate(-1)
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Profile creation',
          message: 'There was an issue creating the profile, please try again.',
        })
      },
    )
  }

  const submitUpdateProfile = (data: CreateProfilePageFormType) => {
    if (updateMutation.isInFlight || !isDirty) return

    updateMutation.commit(
      {
        input: {
          patch: {
            ...data,
            userPriority: Number.parseInt(data.userPriority),
            fullName: `${data.firstName} ${data.lastName}`.trim(),
          },
          rowId: userId,
        },
      },
      () => {
        makeToast({
          type: 'success',
          heading: 'Profile edit',
          message: 'Successfully edited user profile.',
        })
        publishToLocal('USER_PROFILE_EDIT_NOTIFICATION')
        navigate(-1)
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Profile edit',
          message:
            'There was an issue saving profile changes, please try again.',
        })
      },
    )
  }

  const isInFlight = updateMutation.isInFlight || createMutation.isInFlight

  const statusOptions = [
    {
      value: 'ACTIVE',
      label: 'Active',
      disabled: false,
    },
    {
      value: 'SUSPENDED',
      label: 'Suspended',
      disabled: false,
    },
    {
      value: 'DEACTIVATED',
      label: 'Deactivated',
      disabled: false,
    },
  ].filter(({ value }) => userType === 'EXTERNAL_USER' || value !== 'SUSPENDED')

  const companyOptions = companies?.nodes.map((company) => {
    return {
      value: company.rowId,
      label: company.name,
      disabled: false,
    }
  })

  const userPriorityOptions = [
    {
      value: '0',
      label: 'Lowest',
      disabled: false,
    },
    {
      value: '1',
      label: 'Low',
      disabled: false,
    },
    {
      value: '2',
      label: 'Medium',
      disabled: false,
    },
    {
      value: '3',
      label: 'High',
      disabled: false,
    },
    {
      value: '4',
      label: 'Critical',
      disabled: false,
    },
  ]

  return (
    <Layout.Body className="create-profile-page" includeRail>
      <FormProvider {...formMethods}>
        <Form
          onSubmit={
            userId
              ? handleSubmit(submitUpdateProfile)
              : handleSubmit(submitCreateProfile)
          }
        >
          <div className="small-width">
            <Grid.Container className="hc-pt-2x hc-pb-expanded">
              <Grid.Item xs={2} className="hc-pr-lg" />
              <Grid.Item xs={8} className="hc-pr-lg">
                <div className="hc-ta-left">
                  <h1>{userId ? 'Edit profile' : 'Create new profile'}</h1>
                </div>
                <div className="hc-mt-xl">
                  <ControlledFormField
                    className="hc-mb-md"
                    label="First name"
                    placeholder="Enter name"
                    required
                    name="firstName"
                  />
                  <ControlledFormField
                    className="hc-mb-md"
                    label="Last name"
                    placeholder="Enter name"
                    required
                    name="lastName"
                  />
                  <ControlledFormField
                    className="hc-mb-md"
                    label="Job title"
                    placeholder="Enter job title"
                    required
                    name="jobTitle"
                  />
                  <ControlledFormField
                    className="hc-mb-md"
                    label="Gender pronouns"
                    placeholder="i.e. She/Her/Hers, He/Him/His"
                    name="pronouns"
                  />
                  <ControlledFormField
                    className="hc-mb-md"
                    label="Email"
                    placeholder="Enter email"
                    required
                    name="email"
                  />
                  <ControlledFormField
                    className="hc-mb-md"
                    label="Programs"
                    name="programs"
                    required
                    type="checkboxes"
                    options={[
                      {
                        value: PROGRAM_A_A_3D_ACCREDITATION,
                        label: getModuleProgramTypeText(
                          PROGRAM_A_A_3D_ACCREDITATION,
                        ),
                      },
                    ]}
                  />
                  <ControlledFormField
                    className="hc-mb-md"
                    label="Role"
                    placeholder="Select role"
                    name="userType"
                    required
                    type="select"
                    options={[
                      {
                        value: 'EXTERNAL_USER',
                        label: 'Assignee',
                        disabled: false,
                      },
                      {
                        value: 'REVIEWER',
                        label: 'Reviewer',
                        disabled: false,
                      },
                      {
                        value: 'ADMIN',
                        label: 'Admin',
                        disabled: false,
                      },
                    ]}
                  />
                  <ControlledFormField
                    className="hc-mb-md"
                    label="Company"
                    placeholder="Select company"
                    name="companyId"
                    required
                    type="select"
                    options={companyOptions}
                  />
                  <ControlledFormField
                    className="hc-mb-md"
                    label="Status"
                    required
                    placeholder="Select status"
                    name="status"
                    type="select"
                    options={statusOptions}
                  />
                  <ControlledFormField
                    className="hc-mb-md"
                    label="Priority"
                    placeholder="Select priority"
                    name="userPriority"
                    type="select"
                    options={userPriorityOptions}
                  />
                </div>
                <div className="buttons hc-mt-md">
                  <Button
                    className="hc-mr-md button-width"
                    disabled={isInFlight}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button-width"
                    type="submit"
                    disabled={isInFlight || !isDirty}
                  >
                    {userId ? 'Update profile' : 'Create profile'}
                    {isInFlight && <Spinner className="hc-ml-sm" />}
                  </Button>
                </div>
              </Grid.Item>
              <Grid.Item xs={2} />
            </Grid.Container>
          </div>
        </Form>
      </FormProvider>
    </Layout.Body>
  )
}
