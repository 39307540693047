import { graphql } from 'react-relay'
import { useGwtUser } from '../../../hooks'
import { useSubscriptionRefreshQuery } from '../../../hooks/useRefresh'
import { ToDoCard } from './ToDoCard'
import { ToDoAndUpcomingContainerQuery } from './__generated__/ToDoAndUpcomingContainerQuery.graphql'

export const ToDoAndUpcomingContainer = () => {
  const user = useGwtUser()
  const data = useSubscriptionRefreshQuery<ToDoAndUpcomingContainerQuery>(
    graphql`
      query ToDoAndUpcomingContainerQuery($assignedToId: UUID!) {
        flows(
          condition: { assignedToId: $assignedToId }
          filter: { assignmentStatus: { notIn: [ACCEPTED] } }
        ) {
          nodes {
            rowId
            name
            assignedTo {
              id
              rowId
            }
            assignedAt
            dueAt
            linkedType
            assignmentStatus
            ...FlowLink_flow
            ...StatusChip_flow
          }
        }
      }
    `,
    {
      assignedToId: user.rowId,
    },
    {
      component: 'ASSIGNEE_TODO_CARD',
      uniqueComponentId: '',
      onNotification: [
        'ASSIGN_TEMPLATE_NOTIFICATION',
        'REVIEWER_MODULE_START_NEXT_ATTEMPT_NOTIFICATION',
        'REVIEWER_MODULE_BUTTON_NOTIFICATION',
      ],
      includeSelfNotifications: true,
    },
  )

  const groupModules = data.flows?.nodes.filter(
    (module) => module.linkedType === 'MODULE_GROUP',
  )

  const inProgressModules = data.flows?.nodes.filter(
    (module) => module.assignmentStatus !== 'NOT_STARTED',
  )

  const upcomingModules = data.flows?.nodes.filter(
    (module) => module.assignmentStatus === 'NOT_STARTED',
  )

  return (
    <ToDoCard
      groupModules={groupModules}
      inProgressModules={inProgressModules}
      upcomingModules={upcomingModules}
      className={
        upcomingModules !== undefined && upcomingModules.length > 0
          ? 'to-do-card'
          : 'cardLayout'
      }
    />
  )
}
