/**
 * @generated SignedSource<<3ee434fa33c56b999ba4cd6e3f144e67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProgramType = "A_A_3_D_ACCREDITATION" | "NONE" | "_3_D_STYLING" | "%future added value";
export type SoftwareType = "BROWZWEAR" | "CLO" | "NONE" | "OPTITEX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AssentCertificationsBarChart$data = {
  readonly modules: {
    readonly nodes: ReadonlyArray<{
      readonly flow: {
        readonly assignedToId: UUID | null;
      } | null;
      readonly programType: ProgramType | null;
      readonly softwareType: SoftwareType | null;
    }>;
  } | null;
  readonly " $fragmentType": "AssentCertificationsBarChart";
};
export type AssentCertificationsBarChart$key = {
  readonly " $data"?: AssentCertificationsBarChart$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssentCertificationsBarChart">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssentCertificationsBarChart",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "flow": {
              "assignmentStatus": {
                "equalTo": "ACCEPTED"
              }
            }
          }
        }
      ],
      "concreteType": "ModulesConnection",
      "kind": "LinkedField",
      "name": "modules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "softwareType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Flow",
              "kind": "LinkedField",
              "name": "flow",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "assignedToId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "modules(filter:{\"flow\":{\"assignmentStatus\":{\"equalTo\":\"ACCEPTED\"}}})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0764147c3b2bef0a62705e0b5c9fb8f3";

export default node;
