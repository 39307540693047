import { Grid, Layout } from '@enterprise-ui/canvas-ui-react'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { SuspenseRingLoader } from '../../../loadingComponents/SuspenseRingLoader'
import { titleAtom } from '../../../state/atoms'
import { AssigneeDiscussionCard } from './AssigneeDiscussionCard'
import { HistoryCard } from './HistoryCard'
import { ToDoAndUpcomingContainer } from './ToDoAndUpcomingContainer'
import './Assignee.scss'

export const AssigneeDashboard = () => {
  const [, setTitle] = useAtom(titleAtom)

  useEffect(() => {
    setTitle('Assignee')
  })

  return (
    <Layout.Body includeRail>
      <Grid.Container className="assignee hc-pl-expanded hc-pr-expanded hc-pt-2x hc-pb-2x">
        <Grid.Item xs={4} className="hc-pr-lg">
          <SuspenseRingLoader>
            <ToDoAndUpcomingContainer />
          </SuspenseRingLoader>
        </Grid.Item>

        <Grid.Item xs={4} className="hc-pr-lg">
          <SuspenseRingLoader>
            <AssigneeDiscussionCard />
          </SuspenseRingLoader>
        </Grid.Item>
        <Grid.Item xs={4}>
          <SuspenseRingLoader>
            <HistoryCard />
          </SuspenseRingLoader>
        </Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}
