import { Card, Grid, Divider } from '@enterprise-ui/canvas-ui-react'
import { StatusChip } from '../../Flows/StatusChip'

export const UpcomingCard = ({
  groupModules,
  upcomingModules,
  moduleGroupLinks,
  inProgressModules,
}) => {
  return (
    <Card className="hc-mt-expanded upcoming-card">
      <div className="hc-pa-none cardHeader flex header hc-pa-lg">
        <Grid.Container spacing="none" align="center" className="flex-grow">
          <Grid.Item xs={12}>
            <div className="card-header-text">Upcoming</div>
          </Grid.Item>
        </Grid.Container>
      </div>
      <Divider />
      <div className="hc-pa-none card-scroll upcoming-scroll-area flex-grow">
        {upcomingModules?.map((flow) => {
          // Finding group name for module
          let groupModuleName = ''
          let groupModuleId = ''
          let groupModuleIndex = moduleGroupLinks?.nodes.findIndex(
            (moduleGroupLink) => moduleGroupLink.moduleFlowId === flow.rowId,
          )
          if (groupModuleIndex !== undefined && groupModuleIndex !== -1) {
            groupModuleId =
              moduleGroupLinks?.nodes[groupModuleIndex].moduleGroupFlowId
            const matchedGroupModule = groupModules?.find(
              (groupModule) => groupModule.rowId === groupModuleId,
            )
            if (matchedGroupModule !== undefined) {
              groupModuleName = matchedGroupModule?.name
            }
          }

          // Finding which module needs to be completed before
          const relatedGroupModuleLinks = moduleGroupLinks.nodes.filter(
            (moduleGroupLink) =>
              moduleGroupLink.moduleGroupFlowId === groupModuleId,
          )
          const previousGroupModuleLink = relatedGroupModuleLinks.find(
            (moduleGroupLink) =>
              moduleGroupLink.groupIndex ===
              moduleGroupLinks.nodes[groupModuleIndex]?.groupIndex - 1,
          )
          const needsToBeCompletedModule = [
            ...inProgressModules,
            ...upcomingModules,
          ].find(
            (module) => module.rowId === previousGroupModuleLink?.moduleFlowId,
          )
          return (
            <div key={flow.rowId}>
              <Grid.Container
                spacing="none"
                align="center"
                className="hc-pa-lg"
              >
                <Grid.Item xs={8}>
                  <div className="to-do-header-text hc-clr-grey02">
                    {flow.name}
                  </div>
                  <div className="to-do-card-text">
                    <div className="hc-clr-grey02">
                      {needsToBeCompletedModule !== undefined &&
                        needsToBeCompletedModule?.name &&
                        `After completing ${needsToBeCompletedModule.name}`}
                    </div>
                    {groupModuleName !== '' && (
                      <div className="hc-clr-grey02">{`Part of the ${groupModuleName}`}</div>
                    )}
                  </div>
                </Grid.Item>
                <Grid.Item xs={4} align="right" className="hc-pr-lg">
                  <StatusChip flowRef={flow} className="hc-bg-grey04" />
                </Grid.Item>
              </Grid.Container>
              <Divider />
            </div>
          )
        })}
        <Divider />
      </div>
    </Card>
  )
}
