/**
 * @generated SignedSource<<596dcc12fd048617be90c96a14918751>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AssignmentDueAtStatus = "DUE_SOON" | "NONE" | "OVERDUE" | "%future added value";
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type ProgramType = "A_A_3_D_ACCREDITATION" | "NONE" | "_3_D_STYLING" | "%future added value";
export type SoftwareType = "BROWZWEAR" | "CLO" | "NONE" | "OPTITEX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InsightsDashboardFragment_activities$data = {
  readonly flowReviewers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly reviewer: {
          readonly flowsByAssignedToId: {
            readonly totalCount: number;
          };
          readonly fullName: string;
        } | null;
      };
    }>;
  } | null;
  readonly gwtUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly moduleAssignedData: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly flow: {
          readonly name: string;
        } | null;
        readonly id: string;
      };
    }>;
  } | null;
  readonly moduleCompletionData: {
    readonly nodes: ReadonlyArray<{
      readonly flow: {
        readonly assignedToId: UUID | null;
        readonly assignmentDueAtStatus: AssignmentDueAtStatus | null;
        readonly assignmentStatus: AssignmentStatus;
        readonly name: string;
      } | null;
      readonly id: string;
      readonly programType: ProgramType | null;
      readonly softwareType: SoftwareType | null;
    }>;
  } | null;
  readonly statusPiechartData: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly flow: {
          readonly assignmentDueAtStatus: AssignmentDueAtStatus | null;
          readonly assignmentStatus: AssignmentStatus;
          readonly dueAt: Date | null;
          readonly name: string;
        } | null;
        readonly id: string;
        readonly softwareType: SoftwareType | null;
      };
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AssentCertificationsBarChart">;
  readonly " $fragmentType": "InsightsDashboardFragment_activities";
};
export type InsightsDashboardFragment_activities$key = {
  readonly " $data"?: InsightsDashboardFragment_activities$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsightsDashboardFragment_activities">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "flow": {
        "flowType": {
          "notEqualTo": "BLUEPRINT"
        }
      }
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignmentStatus",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignmentDueAtStatus",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./InsightsDashboardFragment_refetchable.graphql')
    }
  },
  "name": "InsightsDashboardFragment_activities",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssentCertificationsBarChart"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "userType": "EXTERNAL_USER"
          }
        }
      ],
      "concreteType": "GwtUsersConnection",
      "kind": "LinkedField",
      "name": "gwtUsers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GwtUsersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GwtUser",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": "gwtUsers(condition:{\"userType\":\"EXTERNAL_USER\"})"
    },
    {
      "alias": "statusPiechartData",
      "args": (v2/*: any*/),
      "concreteType": "ModulesConnection",
      "kind": "LinkedField",
      "name": "modules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModulesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Flow",
                  "kind": "LinkedField",
                  "name": "flow",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dueAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "modules(filter:{\"flow\":{\"flowType\":{\"notEqualTo\":\"BLUEPRINT\"}}})"
    },
    {
      "alias": "moduleCompletionData",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "flow": {
              "assignmentStatus": {}
            }
          }
        }
      ],
      "concreteType": "ModulesConnection",
      "kind": "LinkedField",
      "name": "modules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programType",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Flow",
              "kind": "LinkedField",
              "name": "flow",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "assignedToId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "modules(filter:{\"flow\":{\"assignmentStatus\":{}}})"
    },
    {
      "alias": "moduleAssignedData",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "flow": {
              "assignedToExists": true
            }
          }
        }
      ],
      "concreteType": "ModulesConnection",
      "kind": "LinkedField",
      "name": "modules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModulesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Flow",
                  "kind": "LinkedField",
                  "name": "flow",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "modules(filter:{\"flow\":{\"assignedToExists\":true}})"
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "FlowReviewersConnection",
      "kind": "LinkedField",
      "name": "flowReviewers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FlowReviewersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FlowReviewer",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GwtUser",
                  "kind": "LinkedField",
                  "name": "reviewer",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FlowsConnection",
                      "kind": "LinkedField",
                      "name": "flowsByAssignedToId",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "flowReviewers(filter:{\"flow\":{\"flowType\":{\"notEqualTo\":\"BLUEPRINT\"}}})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "5cfb7ce40a6e4c833881b7ab37e89d9e";

export default node;
