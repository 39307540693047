/**
 * @generated SignedSource<<9a6b62cc607b8e5e06974f058a42f441>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ProgramType = "A_A_3_D_ACCREDITATION" | "NONE" | "_3_D_STYLING" | "%future added value";
export type SoftwareType = "BROWZWEAR" | "CLO" | "NONE" | "OPTITEX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GroupTemplateModulesTablePaginationFragment_modules$data = {
  readonly modules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly flow: {
          readonly durationInSeconds: number | null;
          readonly flowReviewers: {
            readonly nodes: ReadonlyArray<{
              readonly reviewer: {
                readonly fullName: string;
              } | null;
            }>;
          };
          readonly name: string;
          readonly rowId: UUID;
          readonly updatedAt: Date | null;
        } | null;
        readonly programType: ProgramType | null;
        readonly softwareType: SoftwareType | null;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "GroupTemplateModulesTablePaginationFragment_modules";
};
export type GroupTemplateModulesTablePaginationFragment_modules$key = {
  readonly " $data"?: GroupTemplateModulesTablePaginationFragment_modules$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupTemplateModulesTablePaginationFragment_modules">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "modules"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "moduleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "modulesOrderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./GroupTemplateModulesTablePaginationQuery_Refetchable.graphql')
    }
  },
  "name": "GroupTemplateModulesTablePaginationFragment_modules",
  "selections": [
    {
      "alias": "modules",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "moduleFilter"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "modulesOrderBy"
        }
      ],
      "concreteType": "ModulesConnection",
      "kind": "LinkedField",
      "name": "__GroupTemplateModulesTable_modules_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModulesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "programType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "softwareType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Flow",
                  "kind": "LinkedField",
                  "name": "flow",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rowId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "updatedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "durationInSeconds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FlowReviewersConnection",
                      "kind": "LinkedField",
                      "name": "flowReviewers",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FlowReviewer",
                          "kind": "LinkedField",
                          "name": "nodes",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "GwtUser",
                              "kind": "LinkedField",
                              "name": "reviewer",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "fullName",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "8a5e8274fa412aadf16b88b00d039df5";

export default node;
