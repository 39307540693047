import { Grid, Card, Divider } from '@enterprise-ui/canvas-ui-react'
import { FlowLink } from '../../Flows/FlowLink'
import './Assignee.scss'
import { graphql } from 'react-relay'
import { ToDoCardQuery } from './__generated__/ToDoCardQuery.graphql'
import { StatusChip } from '../../Flows/StatusChip'
import { useSubscriptionRefreshQuery } from '../../../hooks/useRefresh'
import { getGwtFormattedDate } from '../../../util/dateUtils'
import { UpcomingCard } from './UpcomingCard'

export const ToDoCard = ({
  groupModules,
  inProgressModules,
  upcomingModules,
  className,
}) => {
  const groupModuleIds = groupModules.map((groupModule) => groupModule.rowId)
  const data = useSubscriptionRefreshQuery<ToDoCardQuery>(
    graphql`
      query ToDoCardQuery($groupModuleIds: [UUID!]) {
        moduleGroupLinks(
          filter: { moduleGroupFlowId: { in: $groupModuleIds } }
        ) {
          nodes {
            moduleGroupFlowId
            moduleFlowId
            groupIndex
          }
        }
      }
    `,
    { groupModuleIds },
    {
      component: 'ASSIGNEE_TODO_CARD',
      uniqueComponentId: '',
      onNotification: [
        'ASSIGN_TEMPLATE_NOTIFICATION',
        'REVIEWER_MODULE_START_NEXT_ATTEMPT_NOTIFICATION',
        'REVIEWER_MODULE_BUTTON_NOTIFICATION',
      ],
      includeSelfNotifications: true,
    },
  )

  return (
    <>
      <Card className={className}>
        <div className="hc-pa-none cardHeader header flex hc-pa-lg">
          <Grid.Container spacing="none" align="center" className="flex-grow">
            <Grid.Item xs={12}>
              <div className="card-header-text">To Do</div>
            </Grid.Item>
          </Grid.Container>
        </div>
        <Divider />
        <div className="hc-pa-none card-scroll to-do-scroll-area flex-grow">
          {inProgressModules?.map((flow) => {
            let groupModuleName = ''
            let groupModuleIndex = data.moduleGroupLinks?.nodes.findIndex(
              (moduleGroupLink) => moduleGroupLink.moduleFlowId === flow.rowId,
            )
            if (groupModuleIndex !== undefined && groupModuleIndex !== -1) {
              const groupModuleId =
                data.moduleGroupLinks?.nodes[groupModuleIndex].moduleGroupFlowId
              const matchedGroupModule = groupModules?.find(
                (groupModule) => groupModule.rowId === groupModuleId,
              )
              if (matchedGroupModule !== undefined) {
                groupModuleName = matchedGroupModule?.name
              }
            }
            return (
              <FlowLink flowRef={flow} key={flow.rowId}>
                <Grid.Container
                  spacing="none"
                  align="center"
                  className="hc-pa-lg card-hover"
                >
                  <Grid.Item xs={8}>
                    <div className="to-do-header-text">{flow.name}</div>
                    <div className="to-do-card-text">
                      <div>
                        {flow.assignedAt &&
                          `Assigned on ${getGwtFormattedDate(flow.assignedAt)}`}
                      </div>
                      <div>
                        {flow.dueAt &&
                          `Due on ${getGwtFormattedDate(flow.dueAt)}`}
                      </div>
                      {groupModuleName !== '' && (
                        <div>{`Part of the ${groupModuleName}`}</div>
                      )}
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={4} align="right" className="hc-pr-lg">
                    <StatusChip flowRef={flow} className="pointer" />
                  </Grid.Item>
                </Grid.Container>
                <Divider />
              </FlowLink>
            )
          })}
        </div>
      </Card>
      {upcomingModules?.length > 0 && (
        <UpcomingCard
          groupModules={groupModules}
          upcomingModules={upcomingModules}
          moduleGroupLinks={data.moduleGroupLinks}
          inProgressModules={inProgressModules}
        />
      )}
    </>
  )
}
